
export default {
    created() {

        this.queryData()
    },
    methods: {
      handleClear(){
        this.pageSize=10;
        this.pageNum=1;
        this.searchStartTime='';
        this.searchEndTime='';
        this.searchAccount='';
        this.queryData();
      },
      async queryData(){
        const loading = this.$loading();
        const res = await this.$axios({
            url: "/api/operLogs/getEventHisList",
            method: "post",
            data: {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                searchStartTime: this.searchStartTime,
                searchEndTime: this.searchEndTime,
                searchAccount: this.searchAccount,
              
            },
        });
        loading.close();
        if (res.code === 2000) {
            this.tableData = res.data.hisList || [];
            this.total = res.data.count || 0;
        }else{
            this.$message({
                message: res.msg || "获取数据失败",
                type: "warning"
            });
        }
      },
       // 翻页相关
       handlePageSizeChange(size) {
        this.pageSize = size;
        this.queryData();
        },
        // 翻页相关
        handlePageNoChange(no) {
         this.pageNum = no;
         this.queryData();
        },
        handleSubmit(){
          this.pageNum=1;
          this.queryData();
        }
    },
  
    data() {
      return {
          datetype: 'date',

        tableData: [],
        pageSize:10,
        pageNum:1,
        total:0,
        searchStartTime:'',
        searchEndTime:'',
        searchAccount:''
      }
    }
  }